<template>
  <v-container fluid>
    <v-form ref="frmRegistro">
      <v-row class="confEmpresa">
        <v-col cols="12" class="centrado">
          <h1>ADMINISTRADOR PRINCIPAL DEL SISTEMA</h1>
        </v-col>

        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Nombre de usuario*"
            placeholder="Nombre de usuario*"
            readonly
            v-model="$store.state.configEmpresa.dataUsuario.usuario"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Nombre(s)*"
            placeholder="Nombre(s)*"
            v-model="$store.state.configEmpresa.dataUsuario.names"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Apellido Paterno*"
            placeholder="Apellido Paterno*"
            v-model="$store.state.configEmpresa.dataUsuario.surname"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Apellido Materno*"
            placeholder="Apellido Materno*"
            v-model="$store.state.configEmpresa.dataUsuario.second_surname"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Teléfono*"
            placeholder="Teléfono*"
            v-model="$store.state.configEmpresa.dataUsuario.phone"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Email*"
            placeholder="Email*"
            v-model="$store.state.configEmpresa.dataUsuario.email"
            :rules="[
              (v) => !!v || 'Dato requerido',
              (v) =>
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
                'Debe ser un correo valido',
            ]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="py-0 centrado">
          <v-btn
            :loading="loading"
            dark
            color="#2C3AB8"
            @click="continuarLlenadoPasoDos()"
            >Guardar y Continuar</v-btn
          >
        </v-col>
        <!-- <v-col cols="4" class="py-0 derecha" @click="Omitir()">
          <v-btn dark color="#A4A6B5">Omitir</v-btn>
        </v-col> -->
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex/dist/vuex.common.js";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["actualizarDatoAdministrador"]),
    async Omitir() {
      this.loading = true;

      this.$store.state.enterprises.datoEmpresaFlag = false;
      this.$store.state.enterprises.datoAdminFlag = false;
      this.$store.state.enterprises.inicioFlag = true;
      this.$store.state.enterprises.step++;
      this.loading = false;
    },
    async continuarLlenadoPasoDos() {
      if (this.$refs.frmRegistro.validate()) {
        this.loading = true;
        await this.actualizarDatoAdministrador();
        this.$store.state.enterprises.datoEmpresaFlag = false;
        this.$store.state.enterprises.datoAdminFlag = false;
        this.$store.state.enterprises.inicioFlag = true;
        this.$store.state.enterprises.step++;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.derecha {
  align-content: right !important;
  text-align: right !important;
}
.izquierda {
  align-content: left !important;
  text-align: left !important;
}
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.logo {
  max-width: 25%;
  background: brown;
}
.blue {
  background: none !important;
  color: #09305e !important;
}
.confEmpresa {
  min-height: 100vh;
  padding: 0 0 200px 0;
}
/* .mt-info {
    padding: 0% 5%;
    margin: 25% 0 0 0;
  } */
</style>
